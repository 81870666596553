export default {
  materia: [
    {
      titulo: "CAPA",
      resumo:
        "Acertos e erros do e-commerce: o que o varejo brasileiro conquistou nos últimos anos",
      imgAutor: require("../assets/imgs/autores/julia.rondinelli.jpg"),
      nomeAutor: "Júlia Rondinelii",
      cargoAutor: "E-Commerce Brasil",
      conteudo:
        "O e-commerce brasileiro vem crescendo ano após ano. Em alguns, passos largos, em outros, baby steps. Porém, é fato de que muito foi feito de forma correta, garantindo cada vez mais a consolidação do mercado. Na matéria desta edição, a equipe do E-Commerce Brasil elencou os principais acertos (e erros também, por que não?) que compõem o cenário atual do mercado eletrônico nacional.",
      tempLeitura: "2",
      imgMateria: require("../assets/imgs/imgs_84/500x500px-CAPA.png"),
      active: true,
      viewModal: false,
    },
    {
      titulo: "ENTREVISTA",
      imgAutor: require("../assets/imgs/autores/lucas.kina.jpg"),
      nomeAutor: "Lucas Kina",
      resumo: "‘Tá’ na moda",
      cargoAutor: "E-Commerce Brasil",
      conteudo:
        "Nesta entrevista, Letícia Vaz, fundadora da LV Store e head de inovação da Nuvemshop, falou sobre a importância de boas estratégias para influenciar e fazer prosperar o mercado de moda no ambiente digital, principalmente quando o assunto são empreendedores, que desbravam águas bravas, mas conquistam um público cada vez mais engajado. Confira os ensinamentos da especialista. ",
      tempLeitura: "2",
      imgMateria: require("../assets/imgs/imgs_84/500x500px-ENTREVISTA.png"),
      active: true,
      viewModal: false,
    },
    {
      titulo: "CASE",
      imgAutor: require("../assets/imgs/autores/amanda.png"),
      nomeAutor: "Amanda Lucio",
      resumo: "A Turma da Xuxa continua dando seu alô",
      cargoAutor: "E-Commerce Brasil",
      conteudo:
        "Em 1986, a Turma da Xuxa fazia sucesso, e, 38 anos depois, seu reinado continua firme. Após o documentário polêmico de 2023, ela voltou aos holofotes com Para Sempre Paquitas e anunciou seu retorno à Globo após 14 anos. Seu “comeback” foi impulsionado por uma comunidade online engajada, que viralizou o meme “que Xou da Xuxa é esse?”, transformado em fenômeno cultural e exemplo da fusão entre referências digitais e o mundo offline. O case desta edição fala sobre como a Estrela continuou se reinventando fazendo o que sempre fez de melhor: encantando crianças.",
      tempLeitura: "2",
      imgMateria: require("../assets/imgs/imgs_84/500x500px.png"),
      active: true,
      viewModal: false,
    },
  ],
};
