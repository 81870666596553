<template>
	<div>
		<NewHeader />
		<div class="background-edicao-revista">
			<p>EDIÇÃO 84</p>
		</div>

		<div id="artigos">
			<router-view :key="$route.params.materia" />
			<div class="section-mais-lidos">
				<h3 class="titulo-mais-lidos">DESTAQUES DA EDIÇÃO 84</h3>
				<div v-for="artigo of materia" :key="artigo.titulo">
					<router-link class="router-link" :to="{
						name: 'materia',
						params: { materia: artigo.titulo }
					}">
						<NewMaisLidos :titulo="artigo.titulo" :resumo="artigo.resumo" :imagem="artigo.imgMateria" />
					</router-link>
				</div>
			</div>
		</div>
		<NewBodyImgs />
		<Maintainers />
		<Footer />
	</div>
</template>

<script>
import artigos from '../store/artigos'
import Footer from './Footer'
import Maintainers from './Maintainers.vue'
import NewBodyImgs from './NewBodyImgs'
import NewHeader from './NewHeader.vue'
import NewMaisLidos from './NewMaisLidos.vue'

export default {
	data() {
		return {
			...artigos
		}
	},
	components: {
		NewHeader,
		NewMaisLidos,
		NewBodyImgs,
		Maintainers,
		Footer
	},
	computed: {
		artigoFiltrado() {
			let propsRota = this.$route.query.artigo
			return propsRota
				? this.materia.find(artigo => artigo.titulo === propsRota)
				: this.materia[0]
		}
	}
}
</script>

<style scoped>
.background-edicao-revista {
	background: url('../assets/imgs/imgs_77/1920x793px.png');
	height: 90px;
	background-repeat: no-repeat;
	background-position: center;
	display: flex;
	align-items: center;
	max-width: 1905px;
	margin: 4em auto 0px auto;
}

.background-edicao-revista p {
	background-color: #fff;
	color: #000;
	margin: 0px 70px;
	text-transform: uppercase;
	line-height: 40px;
	padding: 0px 15px;
	font-size: 23px;
}

#artigos {
	display: flex;
	width: 90%;
	margin: 0px auto;
	margin-top: 100px;
	justify-content: space-between;
	margin-bottom: 70px;
	max-width: 1136.69px;
}

.router-link {
	color: inherit;
	text-decoration: none;
}

.section-mais-lidos {
	width: 30%;
}

.section-mais-lidos .titulo-mais-lidos {
	text-transform: uppercase;
	color: #fff;
	background: #000;
	margin: 0px;
	font-size: 20px;
	line-height: 30px;
	padding-left: 5px;
}

@media (max-width: 450px) {
	#artigos {
		flex-direction: column;
		height: auto;
		/* min-height: 810px; */
		margin-top: 10px;
	}

	.section-mais-lidos {
		width: 100%;
		display: flex;
		flex-direction: column;
		/* position: absolute; */
		margin: 0px auto;
		/* transform: translate(0,21%);
            top: 0px;
            bottom: 0px;
            right: 0px;
            left: 0px; */
	}

	.background-edicao-revista p {
		margin: 0px 19px;
	}
}
</style>
